import React, { Component } from "react"
import { Link, graphql } from 'gatsby'
import { DiscussionEmbed } from "disqus-react";

import Carousel from '../components/carousel'
import { filterByFormat } from '../helpers/FilterByFormat'
import Layout from "../components/layout"


class ArticlePageTemplate extends Component {
  render() {
    const { content, title, categories } = this.props.data.wordpressPost;
    if (this.props.data.wordpressPost.featured_media) {
      if (this.props.data.wordpressPost.featured_media.localFile) {
        if (this.props.data.wordpressPost.featured_media.localFile.childImageSharp) {
          if (this.props.data.wordpressPost.featured_media.localFile.childImageSharp.fluid) {
            const fluid = this.props.data.wordpressPost.featured_media.localFile.childImageSharp.fluid;
          }
        }
      }
    } else {
      const fluid = '';
    }
    const articles = filterByFormat(this.props.data.allWordpressPost.edges, 'standard', 2);
     
    const post = this.props.data.wordpressPost;
    const disqusShortname = "dabacana";
    const disqusConfig = {
      identifier: post.id,
      title: post.title,
    };
    return (
      <Layout>
        <article>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <div className="categories">
            {categories && categories.map(item => {
              return (
                <Link to={`/categories/${item.slug}`} key={item.slug}>{item.name}</Link>
              )
            })}
          </div>
          <div className="wp-content" dangerouslySetInnerHTML={{ __html: content }} />
          <div className="comments">
            <h4>Comments</h4>
            <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
          </div>
        </article>
        <div className="carousel-footer">
          <Carousel title='Articles' items={articles} />
        </div>
      </Layout>
    )
  }
}

export default ArticlePageTemplate;

export const pageQuery = graphql`
         query currentArticlePagePostQuery($id: String!) {
           wordpressPost(id: { eq: $id }) {
             title
             content
              categories {
                name
                slug
              }
             featured_media {
               localFile {
                 childImageSharp {
                   fluid(maxWidth: 1600) {
                     ...GatsbyImageSharpFluid_withWebp
                   }
                 }
               }
             }
           }
           allWordpressPost(sort: { fields: [date], order: DESC }) {
             edges {
               node {
                 id
                 categories {
                   name
                   slug
                 }
                 content
                 title
                 slug
                 excerpt
                 format
                 featured_media {
                   localFile {
                     childImageSharp {
                       fluid(maxWidth: 1600) {
                         ...GatsbyImageSharpFluid_withWebp
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       `
